import React from 'react'
import {
    BrowserRouter as Router,
    Redirect,
    Switch,
    Route
} from 'react-router-dom'

import Playlist from './Playlist'
import About from './About'

/**
 *
 *  Redirect example from:
 *    https://reacttraining.com/react-router/web/api/Redirect
 *  Routes between /playlist and /about as required
 *
 */

const App = () => {
    return (
        <Router>
            <Switch>
                <Route path='/playlist'>
                    <Playlist />
                </Route>
                <Route path='/about'>
                    <About />
                </Route>
                <Redirect from='/' to='/playlist' />
            </Switch>
        </Router>
    )
}

export default App
