import React from 'react'

/**
 *
 *  Content for the about page
 *
 */

const Content = () => {
    return (
        <React.Fragment>
            <header>
                <img id='logo' src='logo.svg' alt='' />
            </header>
            <main>
                <article>
                    <h1>About the project</h1>
                    <p>
                        The base requirements for this project “React Lil'
                        Playlist” (+3) are:
                    </p>
                    <ul>
                        <li>
                            As a user, I want to fill in the following
                            information about my song: title, artist, genre and
                            rating (+1)
                        </li>
                        <li>
                            As a user, I want to be able to click on a button
                            which will add my entered number to my playlist (+1)
                        </li>
                        <li>
                            As a user, I want to be able to see my numbers in an
                            overview, in which all entered data are visible (+1)
                        </li>
                    </ul>
                    <p>I’ve added the following additional requirements:</p>
                    <ul>
                        <li>
                            Sort songs by title: a-z or z-a, artist: a-z or z-a
                            and sort by stars: 5-1 or 1-5 (+1)
                        </li>
                        <li>
                            Remove songs from the state with a Delete button
                            (+1)
                        </li>
                        <li>
                            Add routing from a navbar or menu with a link and
                            page “About”, containing a short story about
                            yourself and this project (+2)
                        </li>
                    </ul>
                    <p>Total: 7 points</p>
                    <h2>About me</h2>
                    <p>
                        Hi! My name is Leon Rijsdam, 42 years old. I have been
                        living in Sommelsdijk on the island of
                        Goeree-Overflakkee for over 7 years and I am married to
                        a real islander, Jessica. After more than 24 years of
                        being active as a creative IT professional in the
                        business world, I am ready for a new challenge! I am
                        currently following a training in Frontend development,
                        a full-time remote training for 6 weeks, focusing on a
                        combination of HTML, CSS, (Vanilla) JavaScript, React,
                        Redux and Jest. As students, we learn to program and
                        collaborate in web development teams, in user experience
                        and interaction, the RESTful web, frontend frameworks
                        and functional and object-oriented programming.
                    </p>
                </article>
            </main>
        </React.Fragment>
    )
}

export default Content
