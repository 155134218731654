import React from 'react'

/**
 *
 *  @param {object} props: Renders the title input field
 *
 */

const Title = props => {
    return (
        <input
            className='formInput'
            type='search'
            name='titleInput'
            placeholder='Title'
            value={props.titleInput}
            onChange={event => props.handleChange(event)}
        />
    )
}

export default Title
