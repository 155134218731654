import React from 'react'

/**
 *
 *  @param {object} props: Renders a dimmed star
 *
 */

const DimmedStar = props => {
    return (
        <img
            onClick={event => props.handleRating(props.starNumber, event)}
            src='star-gray.png'
            alt={'Star' + props.starNumber}
        />
    )
}

export default DimmedStar
