import React from 'react'

import Title from './Header/Title'
import Artist from './Header/Artist'
import Rating from './Header/Rating'
import Genre from './Header/Genre'

/**
 *
 *  @param {object} props
 *  Header component with form for adding new songs to the playlist
 *    Form is inside header because the header background continues under the form
 *      Title, Artist, Genre and Rating are separate components
 *
 */

const Header = props => {
    return (
        <header>
            <img id='logo' src='logo.svg' alt='Leon’s Playlist' />
            <form onSubmit={event => props.handleForm(event)}>
                <Title
                    titleInput={props.form.titleInput}
                    handleChange={props.handleChange}
                />
                <Artist
                    artistInput={props.form.artistInput}
                    handleChange={props.handleChange}
                />
                <Genre
                    genreInput={props.form.genreInput}
                    genreList={props.genreList}
                    handleChange={props.handleChange}
                />
                <Rating
                    handleRating={props.handleRating}
                    ratingInput={props.form.ratingInput}
                />
                <button
                    disabled={props.form.addSongDisabled}
                    className='formButton'
                >
                    Add Song
                </button>
            </form>
        </header>
    )
}

export default Header
