import React from 'react'

import Nav from './Common/Nav'
import Content from './About/Content'

/**
 *
 *  Renders the nav bar and content for the about page
 *
 */

const About = () => {
    return (
        <React.Fragment>
            <Nav activeLink='about' />
            <Content />
        </React.Fragment>
    )
}

export default About
