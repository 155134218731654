import React from 'react'

/**
 *
 *  @param {object} props: Renders a bright star
 *
 */

const BrightStar = props => {
    return (
        <img
            onClick={event => props.handleRating(props.starNumber, event)}
            src='star.png'
            alt={'Star' + props.starNumber}
        />
    )
}

export default BrightStar
