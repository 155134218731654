import React from 'react'

/**
 *
 *  @param {object} props: Renders the genre dropdown menu
 *
 */

const Genre = props => {
    const genreList = props.genreList.map(genre => {
        return (
            <option key={genre} value={genre}>
                {genre}
            </option>
        )
    })
    return (
        <select
            className='selectInput'
            id='songGenre'
            name='genreInput'
            placeholder='Genre'
            value={props.genreInput}
            onChange={event => props.handleChange(event)}
        >
            <option value='Genre'>Genre</option>
            <option disabled>──────────</option>
            {genreList}
        </select>
    )
}

export default Genre
