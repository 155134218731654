import React from 'react'

/**
 *
 *  @param {object} props
 *  Renders the SongList component using the HTML table element
 *
 */

const SongList = props => {
    // renders each song as a table row (tr) with columns (td)
    const songList = props.songList.map((song, index) => {
        return (
            <tr key={index}>
                <td>{song.title}</td>
                <td>{song.artist}</td>
                <td>{song.genre}</td>
                <td>
                    {song.rating} Stars
                    <img
                        onClick={event => props.handleDelete(song.id, event)}
                        src='delete.png'
                        alt='delete'
                    />
                </td>
            </tr>
        )
    })

    const {column, order} = props.sortState

    let sortTriangle1 = ''
    if (column === 'title') {
        if (order) {
            sortTriangle1 = (
                <img
                    className='sortIcon'
                    src='sort-asc.png'
                    alt='Sort by Title'
                />
            )
        } else {
            sortTriangle1 = (
                <img
                    className='sortIcon'
                    src='sort-desc.png'
                    alt='Sort by Title'
                />
            )
        }
    }

    let sortTriangle2 = ''
    if (column === 'artist') {
        if (order) {
            sortTriangle2 = (
                <img
                    className='sortIcon'
                    src='sort-asc.png'
                    alt='Sort by Artist'
                />
            )
        } else {
            sortTriangle2 = (
                <img
                    className='sortIcon'
                    src='sort-desc.png'
                    alt='Sort by Artist'
                />
            )
        }
    }

    let sortTriangle3 = ''
    if (column === 'genre') {
        if (order) {
            sortTriangle3 = (
                <img
                    className='sortIcon'
                    src='sort-asc.png'
                    alt='Sort by Genre'
                />
            )
        } else {
            sortTriangle3 = (
                <img
                    className='sortIcon'
                    src='sort-desc.png'
                    alt='Sort by Genre'
                />
            )
        }
    }

    let sortTriangle4 = ''
    if (column === 'rating') {
        if (order) {
            sortTriangle4 = (
                <img
                    className='sortIcon'
                    src='sort-asc.png'
                    alt='Sort by Rating'
                />
            )
        } else {
            sortTriangle4 = (
                <img
                    className='sortIcon'
                    src='sort-desc.png'
                    alt='Sort by Rating'
                />
            )
        }
    }

    //console.log(props.sortState)

    return (
        <main>
            <table>
                <thead>
                    <tr>
                        <th onClick={event => props.handleSort('title')}>
                            Title{sortTriangle1}
                        </th>
                        <th onClick={event => props.handleSort('artist')}>
                            Artist{sortTriangle2}
                        </th>
                        <th onClick={event => props.handleSort('genre')}>
                            Genre{sortTriangle3}
                        </th>
                        <th onClick={event => props.handleSort('rating')}>
                            Rating{sortTriangle4}
                        </th>
                    </tr>
                </thead>
                <tbody>{songList}</tbody>
            </table>
        </main>
    )
}

export default SongList
