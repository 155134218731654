import React from 'react'
import {BrowserRouter as Router, Link} from 'react-router-dom'

/**
 *
 *  @param {object} props
 *  Navigation component; uses activeLink property to visually
 *    differentiate between the active and inactive page
 *
 */

const Nav = props => {
    return (
        <div>
            <nav>
                <ul>
                    <li>
                        <Router>{/* here to prevent warning */}</Router>
                        <Link
                            className={
                                props.activeLink === 'playlist' ? 'active' : ''
                            }
                            to='/playlist'
                        >
                            Playlist
                        </Link>
                    </li>
                    <li>
                        <Link
                            className={
                                props.activeLink === 'about' ? 'active' : ''
                            }
                            to='/about'
                        >
                            About
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Nav
