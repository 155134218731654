import React from 'react'
import BrightStar from './Rating/BrightStar'
import DimmedStar from './Rating/DimmedStar'

/**
 *
 *  @param {object} props
 *  Renders the rating menu
 *    The word Rating resets the rating to 0 (no rating)
 *    Star1 sets the rating to 1
 *       ...
 *    Star5 sets the rating to 5
 *
 */

const Rating = props => {
    const rating = props.ratingInput

    let star1 = <DimmedStar handleRating={props.handleRating} starNumber='1' />
    if (rating > 0) {
        star1 = <BrightStar handleRating={props.handleRating} starNumber='1' />
    }

    let star2 = <DimmedStar handleRating={props.handleRating} starNumber='2' />
    if (rating > 1) {
        star2 = <BrightStar handleRating={props.handleRating} starNumber='2' />
    }

    let star3 = <DimmedStar handleRating={props.handleRating} starNumber='3' />
    if (rating > 2) {
        star3 = <BrightStar handleRating={props.handleRating} starNumber='3' />
    }

    let star4 = <DimmedStar handleRating={props.handleRating} starNumber='4' />
    if (rating > 3) {
        star4 = <BrightStar handleRating={props.handleRating} starNumber='4' />
    }

    let star5 = <DimmedStar handleRating={props.handleRating} starNumber='5' />
    if (rating > 4) {
        star5 = <BrightStar handleRating={props.handleRating} starNumber='5' />
    }

    return (
        <div className='formRating'>
            <span onClick={event => props.handleRating(0, event)}>Rating</span>
            {star1}
            {star2}
            {star3}
            {star4}
            {star5}
        </div>
    )
}

export default Rating
