import React, {Component} from 'react'

import Header from './Header'
import Nav from './Common/Nav'
import SongList from './SongList'

/**
 *
 *  Playlist: The main component for the playlist interface
 *    In the previous assignment we named Container the main component
 *
 *  State contains the state of 4 form elements (.form)
 *    titleInput: contains the song’s titlem string
 *    artistInput: contains the song’s artist (bandname), string
 *    genreInput: contains the song’s genre, string,
 *    ratingInput: contains the song’s rating, integer from 0 - 5
 *
 *  State contains a separate list of genres loaded via fetch()
 *    whish uses async, await and promise
 *
 *  State contains a list of songs (4 by default)
 *    id: the id of the song listing
 *    title: the title, string
 *    artist: the artist, string
 *    genre: the genre, string
 *    rating: the rating, integer
 *
 *  State contains the last ID (integer) generated to avoid checking and
 *    coding id’s for new songs added to the songs object
 *
 *  State contains a sort object
 *    column: the active column sorted by (title, artist, genre or rating)
 *    order: the active sort order (a-z for ascending, z-a for descending)
 *
 */

export default class Playlist extends Component {
    constructor() {
        super()
        this.state = {
            form: {
                titleInput: '',
                artistInput: '',
                genreInput: 'Genre',
                ratingInput: 5,
                addSongDisabled: 'disabled'
            },
            genres: [],
            songs: [
                {
                    id: 1,
                    title: 'Sabotage',
                    artist: 'Beastie Boys',
                    genre: 'Punk',
                    rating: 5
                },
                {
                    id: 2,
                    title: '9AM',
                    artist: 'Murtagh',
                    genre: 'EDM',
                    rating: 4
                },
                {
                    id: 3,
                    title: 'Blinded',
                    artist: 'Emmit Fenn',
                    genre: 'Easy Listening',
                    rating: 5
                },
                {
                    id: 4,
                    title: 'Insomnia',
                    artist: 'Faithless',
                    genre: 'EDM',
                    rating: 3
                }
            ],
            lastId: 4,
            sort: {
                column: 'title',
                order: false
            }
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleRating = this.handleRating.bind(this)
        this.handleForm = this.handleForm.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleSort = this.handleSort.bind(this)
        this.toggleAddButton = this.toggleAddButton.bind(this)
    }

    // handles form input processing for title, artist and genre
    handleChange(event) {
        event.preventDefault()
        const {name, value} = event.target
        this.setState(state => {
            state.form[name] = value
            state.form.addSongDisabled = this.toggleAddButton()
            return state
        })
    }

    // checks if the user input is valid to enable the Add Song button
    toggleAddButton() {
        const {titleInput, artistInput, genreInput} = this.state.form
        if (titleInput !== '' && artistInput !== '' && genreInput !== 'Genre') {
            return ''
        }
        return 'disabled'
    }

    // handles form imput processing for the rating
    handleRating(id, event) {
        event.preventDefault()
        this.setState(state => {
            state.form.ratingInput = id
            return state
        })
    }

    // handles form processing for adding songs to the playlist
    handleForm(event) {
        event.preventDefault()
        this.setState(state => {
            const {
                titleInput,
                artistInput,
                genreInput,
                ratingInput
            } = state.form
            const duplicate = state.songs.find(song => {
                return (
                    song.title === titleInput &&
                    song.artist === artistInput &&
                    song.genre === genreInput &&
                    song.rating === ratingInput
                )
            })
            if (duplicate === undefined) {
                const newID = state.lastId + 1
                state.songs.push({
                    id: newID,
                    title: titleInput,
                    artist: artistInput,
                    genre: genreInput,
                    rating: ratingInput
                })
                state.lastId = newID
                return state
            }
            return state
        })
    }

    //handles song deletion
    handleDelete(id, event) {
        event.preventDefault()
        this.setState(state => {
            state.songs = state.songs.filter(song => song.id !== id)
            return state
        })
    }

    // handles sorting by column and order
    handleSort(column) {
        this.setState(state => {
            let sortOrder = state.sort.order
            // reset sort order when changing columns
            column !== state.sort.column && (sortOrder = false)
            // sort column in opposite order :)
            state.songs = this.sortColumn(state.songs, sortOrder, column)
            state.sort = {column: column, order: !sortOrder}
            return state
        })
    }

    // callback function for column sort
    sortColumn(array, order, field) {
        if (order) {
            return array.sort((a, b) => {
                if (a[field] < b[field]) {
                    return 1
                } else if (a[field] > b[field]) {
                    return -1
                }
                return 0
            })
        }
        return array.sort((a, b) => {
            if (a[field] < b[field]) {
                return -1
            } else if (a[field] > b[field]) {
                return 1
            }
            return 0
        })
    }

    // used to load a .json file with genres for the selection menu
    // set default sorting: title, ascending
    componentDidMount() {
        this.getData('GET', 'genres.json').then(data => {
            this.setState({genres: data.genres})
        })
        this.handleSort('title')
    }

    // get data from external source (API, local file)
    async getData(method, api, body) {
        try {
            let result = await fetch(api, {
                method: method,
                body: JSON.stringify(body)
            })
            return await result.json()
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        return (
            <React.Fragment>
                <Nav activeLink='playlist' />
                <Header
                    form={this.state.form}
                    handleChange={this.handleChange}
                    handleRating={this.handleRating}
                    handleForm={this.handleForm}
                    genreList={this.state.genres}
                />
                <SongList
                    songList={this.state.songs}
                    handleDelete={this.handleDelete}
                    handleSort={this.handleSort}
                    sortState={this.state.sort}
                />
            </React.Fragment>
        )
    }
}
