import React from 'react'

/**
 *
 *  @param {object} props: Renders the artist input field
 *
 */

const Artist = props => {
    return (
        <input
            className='formInput'
            type='search'
            name='artistInput'
            placeholder='Artist'
            value={props.artistInput}
            onChange={event => props.handleChange(event)}
        />
    )
}

export default Artist
